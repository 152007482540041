﻿table.dataTable {
    width: 100%;
    margin: 0 auto;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
  }
  table.dataTable thead th {
    position: relative;
    background-image: none !important; /* Remove the DataTables bootstrap integration styling */
  }
  
  .dataTables_wrapper .dataTables_filter {
    float: right;
    text-align: right;
  }
  .dataTables_wrapper .dataTables_length {
    float: left;
}
table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  position: absolute;
  top: 12px;
  right: 8px;
  display: block;
  font-family: FontAwesome;
}

table.dataTable thead th.sorting:after {
  content: "\f0dc";
  color: #000;
  font-size: 0.8em;
  padding-top: 0.30em;
}
table.dataTable thead th.sorting_asc:after {
  content: "\f0de";
}
table.dataTable thead th.sorting_desc:after {
  content: "\f0dd";
}

table.withoutSort  thead th.sorting_asc:after {
  content: "";
}

div.dataTables_scrollBody table.dataTable thead th.sorting:after,
div.dataTables_scrollBody table.dataTable thead th.sorting_asc:after,
div.dataTables_scrollBody table.dataTable thead th.sorting_desc:after {
  content: "";
}

/* In Bootstrap and Foundation the padding top is a little different from the DataTables stylesheet */
table.table thead th.sorting:after,
table.table thead th.sorting_asc:after,
table.table thead th.sorting_desc:after {
  top: 8px;
}

.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
}
.dataTables_wrapper .dataTables_length select {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  padding: 4px;
}
  .dataTables_wrapper .dataTables_filter input {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: transparent;
    margin-left: 3px;
  }
  .dataTables_wrapper .dataTables_filter input:focus{
    border: 1px solid #aaa !important;
    border-radius: 3px !important;
    padding: 5px !important;
    background-color: #dcdcdc !important;
    margin-left: 3px !important;
  }
  .dataTables_wrapper .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em;
  }
  .dataTables_wrapper .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    *cursor: hand;
    color: #333 !important;
    border: 1px solid transparent;
    border-radius: 2px;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current,
  .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #333 !important;
    border: 1px solid #979797;
    background-color: white;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, white),
      color-stop(100%, #dcdcdc)
    );
    background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
    background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
    background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
    background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
    background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #666 !important;
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    border: 1px solid #111;
    background-color: #585858;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #585858),
      color-stop(100%, #111)
    );
    background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
    background: -moz-linear-gradient(top, #585858 0%, #111 100%);
    background: -ms-linear-gradient(top, #585858 0%, #111 100%);
    background: -o-linear-gradient(top, #585858 0%, #111 100%);
    background: linear-gradient(to bottom, #585858 0%, #111 100%);
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    outline: none;
    background-color: #2b2b2b;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #2b2b2b),
      color-stop(100%, #0c0c0c)
    );
    background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
    box-shadow: inset 0 0 3px #111;
  }
  .dataTables_wrapper .dataTables_paginate .ellipsis {
    padding: 0 1em;
  }
  .dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(25%, rgba(255, 255, 255, 0.9)),
      color-stop(75%, rgba(255, 255, 255, 0.9)),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 25%,
      rgba(255, 255, 255, 0.9) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 25%,
      rgba(255, 255, 255, 0.9) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 25%,
      rgba(255, 255, 255, 0.9) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 25%,
      rgba(255, 255, 255, 0.9) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 25%,
      rgba(255, 255, 255, 0.9) 75%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter,
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_processing,
  .dataTables_wrapper .dataTables_paginate {
    color: #333;
  }
  
  @media screen and (max-width: 767px) {
    .dataTables_wrapper .dataTables_info,
    .dataTables_wrapper .dataTables_paginate {
      float: none;
      text-align: center;
    }
    .dataTables_wrapper .dataTables_paginate {
      margin-top: 0.5em;
    }
  }
  @media screen and (max-width: 640px) {
    .dataTables_wrapper .dataTables_length,
    .dataTables_wrapper .dataTables_filter {
      float: none;
      text-align: center;
    }
    .dataTables_wrapper .dataTables_filter {
      margin-top: 0.5em;
    }
  }
  
